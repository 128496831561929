import React from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import LegalesNav from '../legalesNav/LegalesNav';

const Legales = () => {
    return (
        <Background>
            <ContainerStyled>
                <Heading>Información legal y exenciones de responsabilidad</Heading>
                <Paragraph>
                    El propósito de este documento es especificar los fundamentos legales de nuestro trabajo
                    y aclarar los límites de nuestra responsabilidad. Este documento se aplica a este sitio web
                    www.deelmarkets.com (en adelante, el "Sitio Web") y a todos nuestros productos y
                    servicios ofrecidos en el Sitio Web.
                </Paragraph>

                <Section id="quien-es-deel-markets">
                    <Subtitle>
                        ¿Quién es Deel Markets?
                    </Subtitle>
                    <Paragraph>
                        Deel Markets es una marca registrada en América. Deel Markets no es una entidad legal, y
                        en cada país donde tenemos presencia tenemos una entidad legal separada que trabaja en
                        los marcos de la legislación local. Desde este punto de vista, podemos designar a Deel
                        Markets como un grupo de empresas agrupadas bajo la marca "Deel Markets".
                        La propiedad de las marcas denominativas y distintivas "Deel Markets" (los números de
                        registro internacional son 2024-00472) pertenece a Deel Market ltd., una empresa
                        registrada en Santa Lucía de Centroamérica.
                    </Paragraph>
                </Section>

                <Section id="que-servicios-ofrece">
                    <Subtitle>
                        ¿Qué tipo de servicios ofrece Deel Markets?
                    </Subtitle>
                    <Paragraph>
                        La lista exacta de nuestros productos y servicios se especifica en nuestro sitio web, sin
                        embargo, queremos afirmar que principalmente ofrecemos servicios de corretaje en CFD.
                        La lista de nuestros productos y servicios están enfocados y dirigidos a todas aquellas
                        personas o individuos que quieran participar del mercado bursátil a través de los contratos
                        por diferencia de precios (CFD).
                    </Paragraph>
                </Section>

                <Section id="sin-oferta-publica">
                    <Subtitle>
                        Sin oferta pública
                    </Subtitle>
                    <Paragraph>
                        Toda la información proporcionada en este sitio web en ningún caso debe entenderse
                        como una oferta pública o cualquier tipo de acuerdo entre usted y cualquiera de las
                        empresas que trabajan bajo la marca comercial Deel Markets. Si desea comprar cualquier
                        producto o recibir algún servicio de nuestra parte, debemos celebrar un acuerdo por
                        separado que especifique los términos y condiciones de nuestras relaciones.
                    </Paragraph>
                </Section>

                <Section id="sin-consejos-inversion">
                    <Subtitle>
                        Sin consejos de inversión

                    </Subtitle>
                    <Paragraph>
                        La información proporcionada en el sitio web no constituye asesoramiento de inversión,
                        asesoramiento financiero, asesoramiento comercial o cualquier otro tipo de consejo, y no
                        debe tratar ningún contenido del sitio web como tal. No recomendamos que usted compre,
                        venda o mantenga ninguna criptomoneda. Lleve a cabo su propia diligencia debida y
                        consulte a su asesor financiero antes de tomar cualquier decisión de inversión. No somos
                        responsables de las decisiones de inversión que tome en función de la información
                        proporcionada en el sitio web. No recaudamos fondos a interés y no le recomendamos que
                        participe en ninguna criptomoneda o comercio de divisas.
                    </Paragraph>
                </Section>

                <Section id="no-comprar-criptomonedas">
                    <Subtitle>
                        No se ofrece comprar una criptomoneda o cualquier
                        instrumento financiero
                    </Subtitle>
                    <Paragraph>
                        No le proponemos que compre una criptomoneda, moneda fiduciaria o cualquier contrato
                        CFD, ningún tipo de valores, opciones y nada en este sitio web debe tomarse como una
                        oferta para comprar, vender o mantener una criptomoneda o cualquier otro instrumento
                        financiero.

                    </Paragraph>
                </Section>

                <Section id="limitacion-responsabilidad">
                    <Subtitle>
                        Limitación de responsabilidad con respecto al
                        contenido del sitio web
                    </Subtitle>
                    <Paragraph>
                        El sitio web puede incluir anuncios y enlaces a sitios externos y páginas de marca
                        compartida o promocionar sitios web o servicios de otras empresas u ofrecerle la
                        posibilidad de descargar software o contenido de otras empresas con el fin de
                        proporcionarle acceso a información y servicios que pueda resultarle útiles o interesantes.
                        No respaldamos dichos sitios ni aprobamos ningún contenido, información, correos
                        electrónicos legales o ilegales (ya sean correos electrónicos no deseados o no), bienes o
                        servicios proporcionados por ellos. No somos responsables ni controlamos esos sitios
                        web, servicios, correos electrónicos, contenido y software y no podemos aceptar ninguna
                        responsabilidad por cualquier pérdida o daño sufrido por usted como resultado de su uso
                        de su sitio web y servicios o de dichos sitios externos y / o de marca compartida.
                        No podemos ejercer control sobre la seguridad o el contenido de la información que pasa
                        por la red, y por la presente excluimos toda responsabilidad de cualquier tipo por la
                        transmisión o recepción de información infractora o ilegal de cualquier naturaleza.
                        No aceptamos ninguna responsabilidad por pérdidas o daños sufridos por usted como
                        resultado de acceder al sitio web y/o sus servicios que contenga cualquier virus o que haya
                        sido dañado maliciosamente.
                    </Paragraph>
                </Section>


            </ContainerStyled>
            <LegalesNav />
        </Background>
    );
};

export default Legales;

const Background = styled.div`
    max-width: 100%;
    min-height: 100vh;
    display: grid;
    justify-content: center;
    margin-bottom: 32px;
`;

const ContainerStyled = styled(Container)`
    line-height: 1.8;
    padding: 96px;
    border-bottom: 0.0625rem solid #ccc;
    width: 100%;  // Asegurar que el contenedor ocupe el ancho completo
    max-width: 1200px;  // Limitar el ancho máximo para pantallas grandes
    @media (max-width: 768px) {
        padding: 24px;
        width: 90%;  // Mejorar ajuste en pantallas pequeñas
    }
`;

const Heading = styled.h1`
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 3rem;
    letter-spacing: -1px;
    line-height: 1.2;
    color: #333;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        font-size: 2rem;  // Reducir el tamaño en pantallas pequeñas
    }
`;

const Section = styled.div`
    margin-bottom: 32px;
`;

const Subtitle = styled.h2`
    margin-bottom: 16px;
    margin-top: 64px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: #222;
    font-size: 24px;
    line-height: 1.2;
    @media (max-width: 768px) {
        font-size: 1.5rem;  // Ajustar subtítulos para pantallas pequeñas
    }
`;

const Paragraph = styled.p`
    font-size: 1rem;
    color: #555;
    margin-bottom: 24px;
    text-align: justify;
    ul {
        margin-top: 16px;
        padding-left: 24px;
        list-style-type: disc;
        
        li {
            margin-bottom: 8px;
        }

        li::marker {
            color: #02B991;
        }
    }
    @media (max-width: 768px) {
        font-size: 0.9rem;  // Ajustar el tamaño de fuente en pantallas pequeñas
    }
`;
