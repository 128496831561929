import { Box, Container, Divider, Typography, useMediaQuery } from '@mui/material';
import { HashLink as RouterLink } from 'react-router-hash-link';
import React from 'react';
import styled from 'styled-components';

const FooterEn = () => {

  const isMobile = useMediaQuery('(max-width:48rem)');

  return (
    <Content>
      <ContainerCustom>
        <List>
          <Box
            component="img"
            sx={{
              width: isMobile ? '10rem' : '15rem',
              position: 'relative',
              left: '-0.3125rem'
            }}
            alt={'Deel Markets'}
            src={'assets/images/logo_footer.png'}
          />
          <Column>
            <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
            <StyledLink to="/legales">Legals</StyledLink>
            <StyledLink to="/terms-and-conditions_en">Terms and Conditions</StyledLink>
          </Column>
        </List>
        <List>
          <StyledLink to="/#Home">Home</StyledLink>
          <StyledLink to="/#Como-funciona">How it works</StyledLink>
          <StyledLink to="/#Tipo-de-cuenta">Account Types</StyledLink>
          <StyledLink to="/#Beneficios">Benefits</StyledLink>
          <StyledLink to="/#CTraders">cTraders</StyledLink>
        </List>
        <List>
          <Column>
            <Typography color="secondary">Support</Typography>
            <StyledLink to="mailto:support@deelmarkets.com">Support@deelmarkets.com</StyledLink>
          </Column>
          <Column>
            <Typography color="secondary">Administration</Typography>
            <StyledLink to="mailto:admin@deelmarkets.com">Admin@deelmarkets.com</StyledLink>
          </Column>
          <Typography color="secondary">Follow us on
            <StyledLink to="https://www.instagram.com/deelmarkets?igsh=MXFkYmlzaTk1cjA5aw==">
              <Img height="18px" src='/assets/images/instagram.png' alt="Instagram de Deel Markets" /> deelmarkets
            </StyledLink>
          </Typography>
        </List>
      </ContainerCustom>
      <Container>
        <Divider color='#64686D' />
        <br />
        <Typography color={'GrayText'} fontSize={14}>
          Risk Warning: Trading derivatives and leveraged products involves a high level of risk, including the risk of losing substantially more than your initial investment. It is not suitable for everyone. Before making any decision regarding a financial product, you should obtain and consider our terms and conditions and legal information available on our website and seek independent advice if necessary.
        </Typography>
        <br />
        <Typography color={'GrayText'} fontSize={14}>
          The information included on this website is of a general nature and does not take into account your objectives, financial situation, or needs. We strive to provide accurate information. However, the information is subject to change at any time without notice. DeelMarkets cannot guarantee or assume any legal responsibility for the relevance, accuracy, timeliness, or completeness of the information.
        </Typography>
        <br />
        <Typography color={'GrayText'} fontSize={14}>
          The information contained on this site is not directed at residents of any country or jurisdiction where such distribution or use would be contrary to local laws or regulations.
        </Typography>
      </Container>
    </Content>
  );
}

export default FooterEn;

const Content = styled.footer`
  background-color: #0B1119;
  padding: 32px 0;
`;

const ContainerCustom = styled(Container)`
  display: grid !important;
  gap: 1.125rem;
  grid-template-columns: 1fr 1fr 1.5fr;
  padding-bottom: 32px;
  @media (max-width: 48rem) {
    grid-template-columns: 1fr;
    gap: 0;
    padding-bottom: 0;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: .75rem;
  height: 12.5rem;
  @media (max-width: 48rem) {
    justify-content: flex-start;
    padding-bottom: 40px;
    height: auto;
  }
`;

const StyledLink = styled(RouterLink)`
  cursor: pointer;
  color: white;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Column = styled.div`
  display: flex;
  gap: .75rem;
  flex-direction: column;
`;

const Img = styled.img`
  margin-left: .25rem;
  position: relative;
  top: .25rem;
`;
