import { Container, Link, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const DesignByEn = () => {
    return (
        <Content>
            <Container>
                <Typography fontSize={13} color={'GrayText'}>
                    Design by <Link href="https://www.instagram.com/eva.diseno" underline="none" color={'secondary'}>E V A • Design Studio</Link>
                </Typography>
            </Container>
        </Content>
    );
};

export default DesignByEn;

const Content = styled.div`
    height: 25px;
    background-color: #111;
    display: flex;
    align-items: center;
    text-align: center;
`;
