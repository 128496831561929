import { Button, Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Card from './components/Card';
import CardMethod from './components/CardMethod';

const HowItWorks = () => {
    const cripto = window.location.origin + '/assets/svg/criptomoneda.svg';

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <BackgroundContent id="Como-funciona">
            <Container>
                <Content>
                    <Typography fontSize={isMobile ? 32 : 48} textAlign={'center'} fontWeight={'bold'}>
                        Cómo funciona
                    </Typography>
                    <Steps>
                        <Card title="Abrir una cuenta" image='assets/images/account.png' description="Haz click en el siguiente botón y sigue nuestro sencillo proceso de registro." button={<Button variant='contained' color='info' href="https://my.deelmarkets.com/en/auth/sign-up">REGISTRARSE</Button>} />
                        <Card title="Depósito" image='assets/images/cash.png' description="Depósito mínimo de 100USD." />
                        <Card title="Comerciar" image='assets/images/notebook.png' description="Opera con cualquiera de los 100 activos y acciones." />
                        <Card title="Retiro" image='assets/images/transfer.png' description="Transfiera sus fondos con facilidad. Retiros en 24hs." canArrow={false} />
                    </Steps>
                </Content>
                <Methods>
                    <CardMethod title="Criptomonedas" icon={cripto} />
                </Methods>
            </Container>
        </BackgroundContent>
    );
};

export default HowItWorks;

const BackgroundContent = styled.div`
    background-color: #EEF8FF;
    position: relative;
    width: 100%;
    height: auto;
    padding: 2rem 0;
    @media (max-width: 768px) {
        padding: auto;
        max-width: auto;
        padding: 0 0 2rem 0;
    }
`;

const Content = styled.div`
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
`;

const Steps = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-top: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding: auto;
    }
`;

const Methods = styled.div`
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding-top: 2rem;
    @media (max-width: 768px) {
        gap: 6px;
        flex-direction: column;
    }
`;
