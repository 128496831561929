import React from 'react';
import styled from 'styled-components';
import { HashLink as RouterLink } from 'react-router-hash-link';

const LegalesNav = () => {
    return (
        <Container>
            <Title>Información legal y exenciones de responsabilidad</Title>
            <Columns>
                <LeftColumn>
                    <Item to="/legales#quien-es-deel-markets">¿Quién es Deel Markets?</Item>
                    <Item to="/legales#que-servicios-ofrece">¿Qué tipo de servicios ofrece Deel Markets?</Item>
                    <Item to="/legales#sin-consejos-inversion">Sin consejos de inversión</Item>
                </LeftColumn>
                <RightColumn>
                    <Item to="/legales#sin-oferta-publica">Sin oferta pública</Item>
                    <Item to="/legales#no-comprar-criptomonedas">No se ofrece comprar una criptomoneda o cualquier instrumento financiero</Item>
                    <Item to="/legales#limitacion-responsabilidad">Limitación de responsabilidad con respecto al contenido del sitio web</Item>
                </RightColumn>
            </Columns>
        </Container>
    );
};

export default LegalesNav;

const Container = styled.div`
    display: grid;
    place-items: center;
`;

const Columns = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px; /* Ajusta el ancho máximo según lo necesario */
`;

const LeftColumn = styled.div`
    flex: 1;
    padding-right: 32px;
`;

const RightColumn = styled.div`
    flex: 1;
    padding-left: 32px;
`;

const Title = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 3rem;
    line-height: 84px;
    color: #383838;
    margin: 5rem 0;
    text-align: center;
`;

const Item = styled(RouterLink)`
    font-size: 15px;
    color: #555;
    margin-bottom: 16px;
    border-bottom: 0.0625rem solid #ccc;
    padding-bottom: 8px;
    cursor: pointer;
    text-decoration: none;
    display: block; /* Asegura que cada enlace ocupe toda la línea */

    &:hover {
        text-decoration: underline;
    }
`;
