import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Navbar from './components/navbar/Navbar';
import { theme } from './theme/theme';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// Importar componentes en Español
import Banner from './components/banner/Banner';
import HowItWorks from './components/howItWorks/HowItWorks';
import AccountType from './components/accountType/AccountType';
import WhyChooseUs from './components/whyChooseUs/WhyChooseUs';
import CTraders from './components/cTraders/CTraders';
import Footer from './components/footer/Footer';
import DesignBy from './components/designBy/DesignBy';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import Legales from './components/legales/Legales';

// Importar componentes en Inglés
import BannerEn from './components/banner/Banner_en';
import HowItWorksEn from './components/howItWorks/HowItWorks_en';
import AccountTypeEn from './components/accountType/AccountType_en';
import WhyChooseUsEn from './components/whyChooseUs/WhyChooseUs_en';
import CTradersEn from './components/cTraders/CTraders_en';
import FooterEn from './components/footer/Footer_en';
import DesignByEn from './components/designBy/DesignBy_en';
import PrivacyPolicyEn from './components/privacyPolicy/PrivacyPolicy_en';
import LegalesEn from './components/legales/Legales_en';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [language, setLanguage] = useState('Español');
  const isEnglish = language === 'English';

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Navbar setLanguage={setLanguage} />
        <Routes>
          <Route path="/" element={
            isEnglish ? (
              <div>
                <BannerEn />
                <HowItWorksEn />
                <AccountTypeEn />
                <WhyChooseUsEn />
                <CTradersEn />
              </div>
            ) : (
              <div>
                <Banner />
                <HowItWorks />
                <AccountType />
                <WhyChooseUs />
                <CTraders />
              </div>
            )
          } />
          
          <Route path="/privacy-policy" element={isEnglish ? <PrivacyPolicyEn /> : <PrivacyPolicy />} />
          <Route path="/legales" element={isEnglish ? <LegalesEn /> : <Legales />} />
        </Routes>
        
        {isEnglish ? <FooterEn /> : <Footer />}
        {isEnglish ? <DesignByEn /> : <DesignBy />}
      </Router>
    </ThemeProvider>
  );
}

export default App;
