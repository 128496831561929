import React from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import LegalNavEn from '../legalesNav/LegalesNav_en';

const Legal = () => {
    return (
        <Background>
            <ContainerStyled>
                <Heading>Legal Information and Disclaimers</Heading>
                <Paragraph>
                    The purpose of this document is to specify the legal foundations of our work
                    and clarify the limits of our responsibility. This document applies to this website
                    www.deelmarkets.com (hereinafter, the "Website") and all our products and
                    services offered on the Website.
                </Paragraph>

                <Section id="who-is-deel-markets">
                    <Subtitle>
                        Who is Deel Markets?
                    </Subtitle>
                    <Paragraph>
                        Deel Markets is a registered brand in America. Deel Markets is not a legal entity,
                        and in each country where we have a presence, we have a separate legal entity operating
                        within the frameworks of local legislation. From this perspective, we can designate Deel
                        Markets as a group of companies grouped under the brand "Deel Markets".
                        The ownership of the registered trademarks "Deel Markets" (international registration numbers
                        are 2024-00472) belongs to Deel Market Ltd., a company registered in Saint Lucia, Central America.
                    </Paragraph>
                </Section>

                <Section id="what-services-does-deel-markets-offer">
                    <Subtitle>
                        What types of services does Deel Markets offer?
                    </Subtitle>
                    <Paragraph>
                        The exact list of our products and services is specified on our website; however,
                        we want to state that we mainly offer brokerage services in CFDs.
                        The list of our products and services is focused on individuals who wish to participate
                        in the stock market through contracts for difference (CFDs).
                    </Paragraph>
                </Section>

                <Section id="no-public-offer">
                    <Subtitle>
                        No Public Offer
                    </Subtitle>
                    <Paragraph>
                        All information provided on this website should not be understood as a public offer
                        or any type of agreement between you and any of the companies operating under the
                        trade name Deel Markets. If you wish to purchase any product or receive any service
                        from us, we must enter into a separate agreement specifying the terms and conditions
                        of our relationship.
                    </Paragraph>
                </Section>

                <Section id="no-investment-advice">
                    <Subtitle>
                        No Investment Advice
                    </Subtitle>
                    <Paragraph>
                        The information provided on the website does not constitute investment advice,
                        financial advice, trading advice, or any other type of counsel, and you should not
                        treat any content from the website as such. We do not recommend that you buy,
                        sell, or hold any cryptocurrency. Please conduct your own due diligence and consult
                        your financial advisor before making any investment decisions. We are not responsible
                        for any investment decisions you make based on the information provided on the website.
                        We do not raise interest-bearing funds, and we do not recommend that you participate
                        in any cryptocurrency or currency trading.
                    </Paragraph>
                </Section>

                <Section id="not-buying-cryptocurrency">
                    <Subtitle>
                        No cryptocurrency or any financial instrument is offered for purchase
                    </Subtitle>
                    <Paragraph>
                        We do not propose that you purchase any cryptocurrency, fiat currency, or any CFD contract,
                        nor should any securities, options, or anything on this website be taken as an offer
                        to buy, sell, or hold any cryptocurrency or any other financial instrument.
                    </Paragraph>
                </Section>

                <Section id="limitation-of-liability">
                    <Subtitle>
                        Limitation of Liability Regarding Website Content
                    </Subtitle>
                    <Paragraph>
                        The website may include advertisements and links to external sites and co-branded pages
                        or promote websites or services from other companies or offer you the possibility to
                        download software or content from other companies in order to provide you access to
                        information and services that may be useful or interesting to you. We do not endorse
                        such sites nor approve any content, information, legal or illegal emails (whether
                        spam or not), goods, or services provided by them. We are not responsible for nor do we
                        control those websites, services, emails, content, and software, and we cannot accept
                        any liability for any loss or damage suffered by you as a result of your use of those
                        websites and services or such external and/or co-branded sites. We cannot exert control
                        over the security or content of the information that passes through the network, and we
                        hereby exclude all liability of any kind for the transmission or reception of infringing
                        or illegal information of any nature. We accept no responsibility for any losses or
                        damages suffered by you as a result of accessing the website and/or its services that
                        contain any viruses or that have been maliciously damaged.
                    </Paragraph>
                </Section>
            </ContainerStyled>
            <LegalNavEn />
        </Background>
    );
};

export default Legal;

const Background = styled.div`
                min-width: 100%;
                min-height: 100vh;
                display: grid;
                justify-content: center;
                background-color: white;
                margin-bottom: 32px;
                `;

const ContainerStyled = styled(Container)`
                line-height: 1.8;
                padding: 96px;
                border-bottom: .0625rem solid #ccc;
                @media (max-width: 48rem) {
                    padding: 24px;
                width: 90%;
    }
                `;

const Heading = styled.h1`
                text-align: center;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                font-size: 70px;
                letter-spacing: -1px;
                line-height: 80px;
                color: #333;
                margin-bottom: 2rem;
                @media (max-width: 48rem) {
                    font - size: 32px;
    }
                `;

const Section = styled.div`
                margin-bottom: 32px;
                `;

const Subtitle = styled.h2`
                margin-bottom: 16px;
                margin-top: 64px;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                color: #222;
                font-size: 24px;
                line-height: 28px;
                @media (max-width: 48rem) {
                    font - size: 1.125rem;
    }
                `;

const Paragraph = styled.p`
                font-size: 16px;
                color: #555;
                margin-bottom: 24px;
                text-align: justify;
                ul {
        margin-top: 16px;
        padding-left: 24px;
        list-style-type: disc;
        
        li {
            margin-bottom: 8px;
        }

        li::marker {
            color: #02B991;
        }
    }

                @media (max-width: 48rem) {
                    font - size: 15.2px;
    }
                `;
