import React from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';

const PrivacyPolicyEn = () => {
    return (
        <Background>
            <ContainerStyled>
                <Heading>Privacy Policy</Heading>

                <Section>
                    <Subtitle>General</Subtitle>
                    <Paragraph>
                        This Privacy Policy governs the collection, processing, and use by us of the Personal Information you provide when accessing or using the website www.deelmarkets.com (hereinafter, the "Website") and any product or service we offer (hereinafter, "Services").
                        <br />
                        "Personal Information" refers to information that identifies an individual, such as name, address, email address, financial information, and banking data. We are committed to protecting and respecting your privacy.
                    </Paragraph>
                    <Paragraph>
                        The purpose of this privacy policy is to describe:
                        <ul>
                            <li>The types of personal information we collect and how it may be used;</li>
                            <li>How and why we may disclose your personal information to third parties;</li>
                            <li>The transfer of your personal information;</li>
                            <li>Your right to access, correct, update, and delete your personal information;</li>
                            <li>The security measures we use to protect and prevent the loss, misuse, or alteration of personal information;</li>
                            <li>Our retention of your personal information;</li>
                            <li>Some basic aspects of our use of cookies; please see our cookie policy.</li>
                        </ul>
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Collection and Use of Personal Information</Subtitle>
                    <Paragraph>
                        <strong>Personal information we collect:</strong> We collect the following personal information:
                        <ul>
                            <li>Contact information, such as name, address, and email address;</li>
                            <li>Account information, such as username and password;</li>
                            <li>Financial information, such as bank account numbers, bank statements, and transaction history;</li>
                            <li>Identity verification information, such as images of your government-issued ID;</li>
                            <li>Other information obtained during our KYC procedures, such as information about your financial status and source of funds.</li>
                        </ul>
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Use of Cookies and Similar Technologies</Subtitle>
                    <Paragraph>
                        The website uses cookies. Cookies are small text files that websites you visit place on your computer. They are widely used to make websites work, or work more efficiently, as well as to provide information to the site owners.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>How We Use Your Personal Information</Subtitle>
                    <Paragraph>
                        We may use your personal information to:
                        <ul>
                            <li>Process your transactions and comply with legal requirements;</li>
                            <li>Verify your identity according to applicable law;</li>
                            <li>Detect, investigate, and prevent fraudulent transactions or unauthorized activities;</li>
                            <li>Personalize your experience with the Services;</li>
                            <li>Analyze the use of the website and improve the site.</li>
                        </ul>
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Disclosure and Transfer of Personal Information</Subtitle>
                    <Paragraph>
                        We may disclose your personal information to third parties and to legal and regulatory authorities, and transfer your personal information as described below:
                    </Paragraph>

                    <Subtitle>A. Disclosures to Third Parties</Subtitle>
                    <Paragraph>
                        When processing your transactions, we may share some of your personal information with our third-party service providers who assist with our business operations. Your information will not be sold, exchanged, or shared with third parties without your consent, except to provide Services or as required by law. By using the Website or Services, you consent to the disclosure of your personal information as described in this Privacy Policy. Visitors' non-personally identifiable information may be provided to third parties for marketing, advertising, or other uses. Our third-party service providers are contractually obligated to protect and use such information only for the purposes for which it was disclosed, except as otherwise required or permitted by law. We ensure that such third parties will be subject to terms no less protective than those described in this privacy policy, or those to which we are subject under applicable data protection laws.
                    </Paragraph>

                    <Subtitle>B. Disclosure to Judicial Authorities</Subtitle>
                    <Paragraph>
                        We may share your personal information with law enforcement, data protection authorities, government officials, and other authorities when:

                        <ul>
                            <li>Bound by subpoena, court order, or other legal proceeding;</li>
                            <li>We believe disclosure is necessary to prevent physical harm or financial loss;</li>
                            <li>Disclosure is necessary to report suspected illegal activities;</li>
                            <li>Detect, investigate, and prevent fraudulent transactions or unauthorized or illegal activity;</li>
                            <li>The disclosure is necessary to investigate violations of this privacy policy or any agreements we have with you.</li>
                        </ul>
                    </Paragraph>
                    <Subtitle>C. International Transfers of Personal Information</Subtitle>
                    <Paragraph>
                        We store and process your personal information in data centers around the world, wherever our facilities or service providers are located. As such, we may transfer your personal information between such data centers. Such transfers are carried out in accordance with our legal and regulatory obligations and are made only through protected channels.
                    </Paragraph>

                    <Subtitle>D. Other Circumstances for Disclosure of Personal Information</Subtitle>
                    <Paragraph>We may also disclose your personal information in the following circumstances:
                        <ul>
                            <li>With your consent or as instructed by you. Certain information that you may choose to share may be publicly displayed, such as your username and any content you post when you use interactive areas of our website, such as our online forums;</li>
                            <li>With our current or future parents, affiliates, subsidiaries and with other companies under common control or ownership with us or our offices internationally. We ensure that the listed parties are subject to terms no less protective than those described in this privacy policy, or those to which we are subject under applicable data protection laws;</li>
                            <li>If the sharing of personal information is necessary for the protection of our rights and property, or the rights and property of the current or future parents, affiliates, subsidiaries listed above and with other companies under common control or ownership with us or our offices;</li>
                            <li>Detect, investigate, and prevent fraudulent transactions or unauthorized or illegal activity;</li>
                            <li>The disclosure is necessary to investigate violations of this privacy policy or any agreements we have with you.</li>
                        </ul>
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Your Rights Regarding Your Personal Information</Subtitle>
                    <Paragraph>
                        You may exercise all rights provided for by law, provided that this does not contradict legal
                        requirements in relation to anti-money laundering and countering the financing of terrorism
                        laws, our record-keeping obligations, etc.
                        <br />
                        You have the following rights:
                        <ul>
                            <li>The right to be informed about the processing of your information;</li>
                            <li>The right to data portability;</li>
                            <li>The right to object to the processing of your personal information;</li>
                            <li>The right to access, correct, update, and block inaccurate data.</li>
                        </ul>
                        To exercise these rights, please contact us at support@deelmarkets.com.
                        Within 40 days of receiving your written request, we will provide you with your personal information, including the purposes for which it was used and to whom it was disclosed in accordance with applicable law. We reserve the right to request additional information that may be necessary to provide the proper response to your request in accordance with applicable law, and you agree to such right. Furthermore, if you wish to correct, update, and block inaccurate and/or incorrect data, we have the right to request confirmation of the correct data, such as official documents containing such data.
                        Please note that if we cannot verify your identity via email or in your request at the call center, or in cases of reasonable doubt about your identity, we may ask you to provide proof of identity, including through personal appearance at our office. This is the only way we can avoid disclosing your personal information to someone who may perpetrate your identity.
                        In some cases, we may not be able to change your personal information. In particular, this may occur if your personal information has already been used during the execution of any agreement or transaction, or is specified in any official document, etc.
                        You have the right to withdraw consent for the processing of personal data. You may also exercise your right to be forgotten and delete your personal information from our servers. In the cases stipulated in Article 17 of the GDPR, we will delete the personal data we process, except for those personal data that we are required to store in accordance with the requirements established by applicable law.
                        Please note that if you exercise your right to withdraw consent for the processing of personal data or the right to be forgotten, we will not be able to provide you with our products or services, and we have a special right to terminate all our current agreements with you with the legal consequences of such termination, and you irrevocably acknowledge such our right.
                        To withdraw consent for the processing of personal data and/or to exercise your rights to be forgotten, please contact us at support@deelmarkets.com.
                        Additionally, in this case, for security reasons, we may ask you to present your identity document directly at our office.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Security of Personal Information</Subtitle>
                    <Paragraph>
                        We use a variety of security measures to ensure the confidentiality of your personal information and to protect your personal information against loss, theft, unauthorized access, misuse, alteration, or destruction. These security measures include, but are not limited to: Password-protected directories and databases. Secure Sockets Layer (SSL) technology to ensure that your information is fully encrypted and sent securely over the Internet. Limited access to hosting servers using 2FA and traffic encryption. All confidential financial and/or credit information is transmitted via SSL technology and encrypted in our database. Only authorized personnel are permitted access to your personal information, and this personnel is obligated to treat the information as highly confidential. Security measures will be reviewed periodically in light of new relevant legal and technical advancements.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Retention of Personal Information</Subtitle>
                    <Paragraph>
                        We retain personal information for as long as necessary to fulfill the purposes described in this privacy policy, subject to our own legal and regulatory obligations. In accordance with our record-keeping obligations, we will retain account and other personal information for at least five years after the termination of the respective agreement.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Updates to This Privacy Policy</Subtitle>
                    <Paragraph>
                        This privacy policy may be reviewed, modified, updated, and/or supplemented at any time, without prior notice, at our sole discretion. When we make changes to this privacy policy, we will notify all users of our website and make the modified privacy policy available on our website.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Data Protection Officer</Subtitle>
                    <Paragraph>
                        To exercise all relevant rights, inquiries, or complaints regarding any data protection matter between you and us, please contact our Data Protection Officer at support@deelmarkets.com
                    </Paragraph>
                </Section>
            </ContainerStyled>
        </Background>
    );
};

export default PrivacyPolicyEn;

const Background = styled.div`
                min-width: 100%;
                min-height: 100vh;
                display: flex;
                justify-content: center;
                background-color: white;
                margin-bottom: 2rem;
                `;

const ContainerStyled = styled(Container)`
                line-height: 1.8;
                padding: 6rem;
                border-bottom: 1px solid #ccc;
                @media (max-width: 768px) {
                    padding: 1.5rem;
                width: 90%;
    }
                `;

const Heading = styled.h1`
                text-align: center;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                font-size: 4.375rem;
                letter-spacing: -.0625rem;
                line-height: 5rem;
                color: #333;
                @media (max-width: 768px) {
                    font - size: 2rem;
    }
                `;

const Section = styled.div`
                margin-bottom: 2rem;
                `;

const Subtitle = styled.h2`
                margin-bottom: 1rem;
                margin-top: 4rem;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                color: #222;
                font-size: 1.5rem;
                line-height: 1.75rem;
                @media (max-width: 768px) {
                    font - size: 18px;
    }
                `;

const Paragraph = styled.p`
                font-size: 1rem;
                color: #555;
                margin-bottom: 1.5rem;
                text-align: justify;
                ul {
        margin-top: 1rem;
        padding-left: 1.5rem;
        list-style-type: disc;
        
        li {
            margin-bottom: 0.5rem;
        }

        li::marker {
            color: #02B991;
        }
    }

                @media (max-width: 768px) {
                    font - size: 0.95rem;
    }
                `;

