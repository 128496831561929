import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Card from './components/Card';

const WhyChooseUsEn = () => {

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Content id="Beneficios">
            <Information>
                <Typography color={'secondary'} fontSize={isMobile ? 32 : 48} fontWeight={'bold'}>Why Choose</Typography>
                <br/>
                <Box
                    component="img"
                    sx={{
                        height: isMobile ? 24 : 44,
                    }}
                    alt={'Deel Markets'}
                    src={'assets/images/logo_h_2.png'}
                />
            </Information>
            <Cards>
                <Card
                    title="Security"
                    description="Your security and privacy are our top priorities."
                    image="assets/images/security.png"
                />
                <Card
                    title="Speed"
                    description="Fast execution. Withdrawals in 24 business hours."
                    image="assets/images/speed.png"
                />
                <Card
                    title="Professionalism"
                    description="Benefit from the most personalized support in the industry."
                    image="assets/images/auto.png"
                />
            </Cards>
        </Content>
    )
}

export default WhyChooseUsEn;

const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 56px;
    height: 800px;
    background: radial-gradient(80.44% 80.44% at 50% 50%, #00FFE0 0%, #0B1119 100%);
    @media (max-width: 768px) {
        height: auto;
        padding: 2rem 0;
    }
`;

const Cards = styled.div`
    display: flex;
    gap: 16px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`;

const Information = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    @media (max-width: 768px) {
        margin-bottom: 0;
    }
`;
