import React from 'react';
import styled from 'styled-components';
import { HashLink as RouterLink } from 'react-router-hash-link';

const LegalNavEn = () => {
    return (
        <Container>
            <Title>Legal Information and Disclaimers</Title>
            <Columns>
                <LeftColumn>
                    <Item to="/legales#who-is-deel-markets">Who is Deel Markets?</Item>
                    <Item to="/legales#what-services-does-deel-markets-offer">What types of services does Deel Markets offer?</Item>
                    <Item to="/legales#no-investment-advice">No Investment Advice</Item>
                </LeftColumn>
                <RightColumn>
                    <Item to="/legales#no-public-offer">No Public Offer</Item>
                    <Item to="/legales#not-buying-cryptocurrency">No cryptocurrency or any financial instrument is offered for purchase</Item>
                    <Item to="/legales#limitation-of-liability">Limitation of Liability Regarding Website Content</Item>
                </RightColumn>
            </Columns>
        </Container>
    );
};

export default LegalNavEn;

const Container = styled.div`
    display: grid;
    place-items: center;
`;

const Columns = styled.div`
    display: flex;
    justify-content: space-between; 
    width: 100%;
    max-width: 1200px; /* Adjust the max width as needed */
`;

const LeftColumn = styled.div`
    flex: 1;
    padding-right: 32px;
`;

const RightColumn = styled.div`
    flex: 1;
    padding-left: 32px;
`;

const Title = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 3rem;
    line-height: 84px;
    color: #383838;
    margin: 5rem 0;
    text-align: center;
`;

const Item = styled(RouterLink)`
    font-size: 15px;
    color: #555;
    margin-bottom: 16px;
    border-bottom: 0.0625rem solid #ccc;
    padding-bottom: 8px;
    cursor: pointer;
    text-decoration: none;
    display: block; /* Ensures each link takes up the full line */

    &:hover {
        text-decoration: underline;
    }
`;
