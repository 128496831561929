import React from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';
import LegalesNav from '../legalesNav/LegalesNav';

const PrivacyPolicy = () => {
    return (
        <Background>
            <ContainerStyled>
                <Heading>Política de Privacidad</Heading>

                <Section>
                    <Subtitle>General</Subtitle>
                    <Paragraph>
                        Esta Política de Privacidad rige la recopilación, el procesamiento y el uso por nuestra parte de la Información Personal que nos proporciona al acceder o utilizar un sitio web www.deelmarkets.com (en adelante, el "Sitio Web") y cualquier producto o servicio que ofrezcamos (en adelante, "Servicios").
                        <br />
                        "Información personal" se refiere a la información que identifica a una persona, como el nombre, la dirección, la dirección de correo electrónico, la información financiera y los datos bancarios. Nos comprometemos a proteger y respetar su privacidad.
                    </Paragraph>
                    <Paragraph>
                        El propósito de esta política de privacidad es describir:
                        <ul>
                            <li>Los tipos de información personal que recopilamos y cómo se puede utilizar;</li>
                            <li>Cómo y por qué podemos divulgar su información personal a terceros;</li>
                            <li>La transferencia de su información personal;</li>
                            <li>Su derecho a acceder, corregir, actualizar y eliminar su información personal;</li>
                            <li>Las medidas de seguridad que utilizamos para proteger y prevenir la pérdida, el uso indebido o la alteración de la información personal;</li>
                            <li>Nuestra retención de su información personal;</li>
                            <li>Algunos aspectos básicos de nuestro uso de cookies, consulte nuestra política de cookies.</li>
                        </ul>
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Recopilación y uso de información personal</Subtitle>
                    <Paragraph>
                        <strong>Información personal que recopilamos:</strong> Recopilamos la siguiente información personal:
                        <ul>
                            <li>Información de contacto, como nombre, domicilio y dirección de correo electrónico;</li>
                            <li>Información de la cuenta, como nombre de usuario y contraseña;</li>
                            <li>Información financiera, como números de cuentas bancarias, extracto bancario, actividad e historial de operaciones;</li>
                            <li>Información de verificación de identidad, como imágenes de su identificación emitida por el gobierno;</li>
                            <li>Otra información, obtenida durante nuestros procedimientos de KYC, como información sobre su estado financiero y fuente de fondos.</li>
                        </ul>
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Uso de cookies y tecnologías similares</Subtitle>
                    <Paragraph>
                        El sitio web utiliza cookies. Las cookies son pequeños archivos de texto que los sitios web que visita colocan en su ordenador. Son ampliamente utilizados para hacer que los sitios web funcionen, o funcionen de manera más eficiente, así como para proporcionar información a los propietarios del sitio.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Cómo utilizamos su información personal</Subtitle>
                    <Paragraph>
                        Podemos utilizar su información personal para:
                        <ul>
                            <li>Procesar sus transacciones y cumplir con requisitos legales;</li>
                            <li>Verificar su identidad de acuerdo con la legislación aplicable;</li>
                            <li>Detectar, investigar y prevenir transacciones fraudulentas o actividades no autorizadas;</li>
                            <li>Personalizar su experiencia con los Servicios;</li>
                            <li>Analizar el uso del sitio web y mejorar el sitio.</li>
                        </ul>
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Divulgación y transferencia de información personal</Subtitle>
                    <Paragraph>
                        Podemos divulgar su información personal a terceros y a las autoridades legales cuando sea necesario para cumplir con las obligaciones legales.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Sus derechos con respecto a su información personal</Subtitle>
                    <Paragraph>
                        <ul>
                            <li>El derecho a ser informado sobre el procesamiento de su información;</li>
                            <li>El derecho a la portabilidad de los datos;</li>
                            <li>El derecho a oponerse al tratamiento de su información personal;</li>
                            <li>El derecho a acceder, corregir, actualizar y bloquear datos inexactos.</li>
                        </ul>
                        Para ejercer estos derechos, póngase en contacto con nosotros en support@deelmarkets.com.
                        Dentro de los 40 días posteriores a la recepción de su solicitud por escrito, le
                        proporcionaremos su información personal, incluidos los fines para los que se utilizó y a
                        quién se divulgó de acuerdo con la legislación aplicable. Nos reservamos el derecho de
                        solicitarle información adicional, que pueda ser necesaria para poder proporcionar la
                        debida respuesta a su solicitud de acuerdo con la legislación aplicable y usted está de
                        acuerdo con dicho derecho. Además, si desea corregir, actualizar y bloquear los datos
                        inexactos y/o incorrectos, tenemos derecho a solicitarle la confirmación de los datos
                        correctos, por ejemplo, documentos oficiales que contengan dichos datos.
                        Tenga en cuenta que si no podemos verificar su identidad por mensajes de correo
                        electrónico o en su solicitud al centro de llamadas, o en el caso de dudas razonables sobre
                        su identidad, podemos pedirle que proporcione una prueba de identidad, incluso mediante
                        la comparecencia personal en nuestra oficina. Esta es la única forma en que podemos
                        evitar divulgar su información personal a una persona que pueda perpetrar su identidad.
                        En algunos casos, no podremos cambiar su información personal. En particular, tal caso
                        puede incluir el evento en el que su información personal ya se ha utilizado durante la
                        ejecución de cualquier acuerdo o transacción, o se especifica en cualquier documento
                        oficial, etc.
                        Tiene derecho a retirar el consentimiento para el tratamiento de datos personales.
                        También puede ejercer su derecho al olvido y borrar su información personal de nuestros
                        servidores. En los casos estipulados en el artículo 17 del RGPD, eliminaremos los datos
                        personales que procesamos, a excepción de aquellos datos personales que estemos
                        obligados a almacenar de acuerdo con los requisitos establecidos por la legislación
                        aplicable.
                        Tenga en cuenta que, en caso de que se ejerza su derecho a retirar el consentimiento para
                        el tratamiento de datos personales o el derecho al olvido, no podremos proporcionarle
                        nuestros productos o servicios, y tenemos un derecho especial a rescindir todos nuestros
                        acuerdos actuales con usted con la aplicación de las consecuencias legales de dicha
                        terminación. y usted reconoce irrevocablemente tal nuestro derecho.
                        Para retirar el consentimiento para el tratamiento de datos personales y/o ejercer sus
                        derechos al olvido, póngase en contacto con nosotros en support@deelmarkets.com
                        Además, en este caso, por motivos de seguridad, podemos solicitarle que presente su
                        documento de identidad, directamente en nuestra oficina.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Seguridad de la información personal</Subtitle>
                    <Paragraph>
                        Utilizamos una variedad de medidas de seguridad para garantizar la confidencialidad de su
                        información personal y para proteger su información personal contra pérdida, robo, acceso
                        no autorizado, uso indebido, alteración o destrucción. Estas medidas de seguridad
                        incluyen, pero no se limitan a: Directorios y bases de datos protegidos con contraseña.
                        Tecnología Secure Sockets Layered (SSL) para garantizar que su información esté
                        completamente encriptada y se envíe a través de Internet de forma segura.
                        Acceso limitado a los servidores de alojamiento que utilizan 2FA y cifrado de tráfico.
                        Toda la información financiera confidencial y/o crediticia se transmite a través de la
                        tecnología SSL y se encripta en nuestra base de datos. Solo el personal autorizado tiene
                        permitido el acceso a su información personal, y este personal está obligado a tratar la
                        información como altamente confidencial. Las medidas de seguridad se revisarán
                        periódicamente a la luz de los nuevos avances jurídicos y técnicos pertinentes.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Retención de información personal</Subtitle>
                    <Paragraph>
                        Conservamos la información personal durante el tiempo que sea necesario para cumplir
                        con los fines descritos en esta política de privacidad, sujeto a nuestras propias
                        obligaciones legales y reglamentarias. De acuerdo con nuestras obligaciones de
                        mantenimiento de registros, conservaremos la cuenta y otra información personal durante
                        al menos cinco años después de la terminación del acuerdo respectivo.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Actualizaciones de esta política de privacidad</Subtitle>
                    <Paragraph>
                        Esta política de privacidad puede ser revisada, modificada, actualizada y/o
                        complementada en cualquier momento, sin previo aviso, a nuestra entera discreción.
                        Cuando realicemos cambios en esta política de privacidad, notificaremos a todos los
                        usuarios de nuestro sitio web y pondremos a disposición la política de privacidad
                        modificada en nuestro sitio web.
                    </Paragraph>
                </Section>

                <Section>
                    <Subtitle>Delegado de protección de datos</Subtitle>
                    <Paragraph>
                        Para ejercer todos los derechos, consultas o quejas relevantes sobre cualquier asunto de
                        protección de datos entre usted y nosotros, póngase en contacto en primera instancia con
                        nuestro Oficial de Protección de Datos en support@deelmarkets.com
                    </Paragraph>
                </Section>
            </ContainerStyled>
            <LegalesNav/>
        </Background>
    );
};

export default PrivacyPolicy;

const Background = styled.div`
                min-width: 100%;
                min-height: 100vh;
                display: grid;
                justify-content: center;
                background-color: white;
                margin-bottom: 2rem;
                `;

const ContainerStyled = styled(Container)`
                line-height: 1.8;
                padding: 6rem;
                border-bottom: 1px solid #ccc;
                @media (max-width: 768px) {
                    padding: 1.5rem;
                width: 90%;
    }
                `;

const Heading = styled.h1`
                text-align: center;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                font-size: 4.375rem;
                letter-spacing: -.0625rem;
                line-height: 5rem;
                color: #333;
                @media (max-width: 768px) {
                    font - size: 2rem;
    }
                `;

const Section = styled.div`
                margin-bottom: 2rem;
                `;

const Subtitle = styled.h2`
                margin-bottom: 1rem;
                margin-top: 4rem;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                color: #222;
                font-size: 1.5rem;
                line-height: 1.75rem;
                @media (max-width: 768px) {
                    font - size: 18px;
    }
                `;

const Paragraph = styled.p`
                font-size: 1rem;
                color: #555;
                margin-bottom: 1.5rem;
                text-align: justify;
                ul {
        margin-top: 1rem;
        padding-left: 1.5rem;
        list-style-type: disc;
        
        li {
            margin-bottom: 0.5rem;
        }

        li::marker {
            color: #02B991;
        }
    }

                @media (max-width: 768px) {
                    font - size: 0.95rem;
    }
                `;
